* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  font-family: Georgia;
}

section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.contacts {
  display: flex;
  padding: 2rem 24%;
  flex-direction: column;
}



.contacts .img {
  margin-bottom: 4vh;
}

.contacts .heading {
  text-align: center;
  margin-bottom: 2rem;
  color: #61674d;
  width: 100%;
  font-size: calc(25px + (70 - 25) * ((100vw - 300px) / (1600 - 300)));
}

.contacts .h3 {
  color: black;
  margin-bottom: 4vh;
  font-family: Montserrat;
  font-style: italic;
  opacity: 0.8;
}

.contacts .heading span {
  color: #61674d;
}

.contacts .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.contacts .row form {
  flex: 1 1 25rem;
}

.contacts .row form .box {
  width: 100%;
  margin: 1rem 0rem 2rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #61674d;
  background-color: transparent;
}
.contacts .row form span {
  color: black;
  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
}

.contacts .row form .gender {
  margin: 1rem 0rem 2rem;
  display: flex;
  flex-direction: column;
}

.contacts .row form .gender label {
  margin-left: 0.2rem;
  font-size: 1.6rem;
  color: black;
  cursor: pointer;
}

.contacts .row form .gender #female {
  margin-top: 1rem;
}

.contacts .row form .gender input {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 1px solid #61674d;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s;
}

.contacts .row form .gender input:checked {
  background-color: rgb(167, 131, 9);
}
.contacts .row form .gender .red:checked {
  background-color: #6b1c24;
}

.contacts .row form .gender #female:checked ~ label[for="female"] {
  color: #6b1c24;
}
.contacts .row form .gender #male:checked ~ label[for="male"] {
  color: rgb(167, 131, 9);
}
.contacts .row form .gender #no:checked ~ label[for="no"] {
  color: #6b1c24;
}
.contacts .row form .gender #yes:checked ~ label[for="yes"] {
  color: rgb(167, 131, 9);
}

.btn {
  padding: 1rem 3rem;
  /*  border-radius: 1.5rem; */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.7);
  font-weight: 800;
  cursor: pointer;
  transition: 0.3s;
  background-color: black;
  margin: 0 25%;
  color: white;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(221, 204, 204, 0.555);
  z-index: 100;
  backdrop-filter: blur(1px);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: whitesmoke;
  opacity: 0.5;
}



.modal-window {
  width: fit-content;
  height: fit-content;
  z-index: 2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70vw;
  height: 20dvh;
  font-family: 'Courier New', Courier, monospace;
  background: white;
}



/* .btn:hover {
  background-color: rgb(48, 221, 14);

  box-shadow: 0 0.5rem 1rem rgba(167, 131, 9, 0.7);
} */

@media (max-width: 430px) {
  .contacts {
    padding: 2rem 10%;
    margin-top: -20vh;
  }
  .contacts .row form span {
    color: #61674d;
    font-size: calc(19px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
  }
}

@media (max-width: 400px) {
  .contacts .row form span {
    color: #61674d;
    font-size: calc(18px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
  }

  .contacts img{
    width: 80vw;
  }
  .contacts .h3{
    font-size: 15px;
  }
}
@media (max-width: 360px) {
  .contacts .row form span {
    color: #61674d;
    font-size: calc(16px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
  }
  .contacts .row form .gender label {
    font-size: 1.4rem;
  }
}
