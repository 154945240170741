* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

.active {
  transition: 1;
  background-color: black;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header svg {
  color: whitesmoke;
  width: 3vw;
  position: absolute;
  top: 46%;
  rotate: -15deg;
  transform: translate(20%);
}

.header span {
  font-size: calc(19px + (32 - 22) * ((100vw - 300px) / (1600 - 300)));
  font-optical-sizing: auto;
  font-weight: 800;
  color: black;
  letter-spacing: 5px;
}

.header .logo {
  height: 100vh;
}

.header .header_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 999;
  top: 36%;
}

.guests {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  margin-top: 5vh;
}

.guests .flex_m .img {
  width: 20vw;
  border-radius: 25% 25% 0 0;
}

.cover {
  opacity: 0.3;
  position: absolute;
  height: 100%;
  width: 100%;
}

.table {
  width: 40vw;
}

.guests .text_short {
  background: transparent;
  width: 30%;
  margin-bottom: 5vh;
}
.guests .text_long {
  background: transparent;
  width: 30%;
  margin-bottom: 5vh;
}

.guests .flex_m {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98vw;
  margin-bottom: 5vh;
}
.guests .flex_m img {
  margin-bottom: 5vh;
}
.guests .flex_m span {
  font-size: 1.5rem;
}

.flex_b {
  display: flex;
  flex-direction: column;
}

.flex_b span {
  color: rgb(207, 18, 18);
  font-size: calc(25px + (42 - 25) * ((100vw - 300px) / (1600 - 300)));
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  letter-spacing: 5px;
}

.place {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.place .container {
  display: flex;
  flex-direction: row;
  margin-top: 5vh;
  margin-bottom: 2vh;
}

.place .container img {
  width: 8vw;
  border: solid 1px black;
}
.place .container .place_img_2 {
  margin: 0 3vw;
}

.place .place_img_text {
  margin: 3vh 0;
}

.place .place_text {
  font-family: "Courier New", Courier, monospace;
  color: grey;
  font-size: calc(12px + (32 - 12) * ((100vw - 300px) / (1600 - 300)));
}

.place .place_text_b {
  margin-bottom: 3vh;
}

.place .place_img_location {
  margin-bottom: 3vh;
}

.place .btnPlace {
  margin-bottom: 4vh;
  background-color: #fefaee;
}
.place a {
  text-decoration: none;
  color: #61674d;
}

.plan {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .plan .line {
  background-color: #000000;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  width: 20px;
  height: 1px;
  width: 40vw;
  margin-right: 3vw;
} */

.plan_top {
  display: flex;
  align-items: center;
  width: 100vw;
  justify-content: center;
  margin-bottom: 5vh;
}

.plan .plan_text {
  font-size: 40px;
}

.plan_main {
  display: flex;
  flex-direction: row;
}

.plan_time {
  display: flex;
  width: fit-content;
  flex-direction: column;
  margin-top: 10vh;
}

.plan_disc {
  margin-bottom: 10vw;
  display: flex;
  flex-direction: column;
  margin-left: 4vw;
}
.plan_disc span {
  font-size: 20px;
  margin-bottom: 2vw;
}

.plan_disc_rare {
  opacity: 0.9;
  color: grey;
}

.plan_img {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan .line_up {
  background-color: #000000;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  width: 1px;
  height: 8vw;
}

.plan .time:nth-last-of-type() {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.plan .plan_text {
  margin-top: 0vh;
}

.plan .containerPlan {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.plan .containerPlan .col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 15vw;
  margin: 0 0 0 2vw;
}

.plan .containerPlan .col span {
  font-family: "Courier New", Courier, monospace;
  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));
  color: #61674d;
  font-weight: 700;
}

.plan .containerPlan .col span {
  margin-bottom: 1vw;
}

.enter {
  opacity: 0;
  transform: scale(0.9);
}
.enter-done {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 1000ms;
}
.exit {
  opacity: 1;
}
.exit-done {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

@media (max-width: 430px) {
  .header {
    overflow: hidden;
  }

  .header .logo {
    height: 100vh;
    width: 133vw;
    left: -40px;
  }
  .header .header_text {
    top: 36%;
    left: 8%;
  }

  .header svg {
    color: whitesmoke;
    width: 13vw;
    position: absolute;
    top: 46%;
    rotate: -15deg;
    transform: translate(20%);
  }

  .header span {
    font-size: calc(28px + (42 - 25) * ((100vw - 300px) / (1600 - 300)));
    color: black;
  }
  .logo {
    width: 100vw;
    height: 100vh;
  }
  .plan .time {
    display: flex;
    flex-direction: column;
  }
  .plan .containerPlan {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    justify-content: space-between;
    padding: 0 10vw;
    padding-bottom: 5vw;
  }
  .guests .ring {
    width: 100%;
  }
  .place .place_img_text {
    width: 57vw;
    margin-top: 10vh;
  }
  .place .place_img_location {
    width: 90vw;
  }

  .plan_top {
    justify-content: center;
    margin-bottom: 5vh;
  }

  .plan_main {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100vw;
  }
  .plan_disc {
    margin-bottom: 36vw;
    display: flex;
    flex-direction: column;
  }
  .header .inv {
    width: 40%;
  }
  .guests .flex_m .img {
    width: 51vw;
    border-radius: 50%;
    margin-bottom: 4vh;
  }

  .guests .flex_m span {
    font-size: 1.5rem;
  }
  .guests .babochka {
    width: 40%;
  }
  .guests .text_long {
    width: 75%;
  }
  .guests .text_short {
    width: 75%;
  }
  .place a {
    font-size: 14px;
  }
  .place .container img {
    width: 20vw;
  }

  .place .place_text {
    font-size: 20px;
  }

  .plan .containerPlan .col {
    margin-right: 10vw;
    /* max-width: 40vw; */
    font-size: 20px;
    width: fit-content;
  }
  .plan .containerPlan .col span {
    width: fit-content;
    font-size: 20px;
  }

  .plan_img img {
    width: 23vw;
  }

  .plan .line_up {
    height: 30vw;
  }

  .plan .plan_text {
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .header {
    overflow: hidden;
  }

  .header .logo {
    height: 100vh;
    width: 140vw;
  }
  .place .place_text {
    font-size: 18px;
  }
  .plan_time {
    margin-top: 7vh;
  }
  .plan_disc {
    margin-bottom: 25vw;
  }
  .header span {
    font-size: 21px;
    color: black;
  }
}
@media (max-width: 360px) {
  .place .place_text {
    font-size: 14px;
  }
}
